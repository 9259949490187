.slider-img{
    width: 800px;
    height: 600px;
    object-fit: cover;
    background-color: black;
}

.caption-wrapper {
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin: auto auto 10%;
}

.caption-wrapper h3{
    background-color: rgba(131, 142, 84, 0.66);
    align-self: start;
    padding: 5px 10px;
}
.caption-wrapper p{
    background-color: rgba(131, 142, 84, 0.66);
    padding: 5px 10px;
    font-size: 17px;
}


.learn-more-container{
    position: absolute;
    right: 5%;
    bottom: 15%;
    z-index: 5;
}

@media only screen and (max-width: 800px){
    .learn-more-container{
        display: none;
    }
}
