.get-in-touch-container{
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
    background-color: #D5D8C5;
    align-items: center;

}

.get-in-touch-container h3{
    margin-top: 5px;
}

.get-in-touch-container button{
    max-width: 100%;
    padding: 12px 30px;
    border: 2px solid #000000;
    position: relative;
    overflow: hidden;
    background-color: #D5D8C5;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    transition: .3s;
    z-index: 1;
    color: #000000;
    font-weight: bold;
}

.get-in-touch-container button::before {
    content: '';
    width: 0;
    height: 300%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(40deg);
    background: #8F9086;
    transition: .5s ease;
    display: block;
    z-index: -1;
}

.get-in-touch-container button:hover::before {
    width: 105%;
}

.get-in-touch-container button:hover {
    color: #111;
    border: 2px solid #111;
}


@media only screen and (max-width: 800px){

    .get-in-touch-container{
        justify-content: center;
    }
    .get-in-touch-container h3{
        font-size: 16px;
    }
    .get-in-touch-container button{
        transform: scale(0.7);
    }
}