@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    position: relative;
    height: 100vh;
    margin: 0;
}

body::before {
    content: '';
    background-image: url('../public/images/glasses_bg.png');
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.4; /* Adjust the opacity as needed */
    z-index: -1;
}