.flex-container{
    display: flex;
    flex-wrap: wrap;
}
.flex-container div{
    width: 400px;
    height: 300px;
    min-width: 250px;
    min-height: 250px;
    margin: 20px 20px 20px 30px;
    text-align: center;
    transition: .2s ease-in-out;
    cursor: pointer;
}
.flex-container div img{
    width: 100%;
    height: 100%;
}

.flex-container div:hover{
    transform: scale(1.05);
}

.flex-container div p{

}

@media only screen and (max-width: 800px){
    .flex-container div{
        margin: 35px auto 0;
        width: 100%;
    }
    .flex-container div img{
        width: 110%;
        height: 100%;
    }

}

.animated-component {
    opacity: 0;
    transform: translateY(200px);
    transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
}

.animated-component.animate {
    opacity: 1;
    transform: translateY(0);
}

.animated-component-from-left {
    opacity: 0;
    transform: translateX(30%);
    transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
}

.animated-component-from-left.animate {
    opacity: 1;
    transform: translateY(0);
}

.animated-component-from-right {
    opacity: 0;
    transform: translateX(-30%);
    transition: transform 1.2s ease-in-out, opacity 0.8s ease-in-out;
}

.animated-component-from-right.animate {
    opacity: 1;
    transform: translateY(0);
}
