.wrapper{
    height: 40px;
    display: flex;
    background: #8E6CD6;
    justify-content: space-between;
    padding-right: 20px;
    align-items: center;
    padding-left: 23%;
}

.info_container,
.sm-container{
    display: flex;
    justify-content: space-between;

}
.sm-container li,
.info_container li{
    list-style: none;
    margin-right: 15px;
}

.info_container i{
    margin-right: 5px;

}
@media only screen and (max-width: 600px){
    .wrapper{
        display: none;
    }
}