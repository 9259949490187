.about-us-container{
    flex-wrap: wrap;
    width: 100%;
}
.about-us-container p{
    width: 80%;
}

.about-us-container img{
    height: 100px;
    width: 125px;
    margin-bottom: 5px;
    flex-wrap: wrap;
    object-fit: contain;
}
.about-us-images{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 100px;
}

@media only screen and (min-width: 800px){
    .contact-loc{
        margin: 35px auto 0;
        width: 100%;
    }
}