.logo {
    margin: auto;
}

.nav_container {
    display: flex;
    flex-direction: column;
    margin: auto;
}


.sticky {
    height: 70px;
    background-color: #BEC2C5;
    position: sticky;
    transition: .5s ease-in-out;
    padding-top: 0;
    box-shadow: -7px 20px 59px -3px rgba(0,0,0,0.75);
    -webkit-box-shadow: -7px 20px 59px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: -7px 41px 20px -3px rgba(0,0,0,0.75);
}


.navbar-style {
    z-index: 1000000;
    transition: .5s ease-in-out;
    position: sticky;
    top: 0;
    left: 0;
}

.navigation-item {
    color: #000000;
    text-decoration: none;
    font-weight: 500;
}

.navigation-item:hover {
    border-bottom: black solid 2px;
    color: #000000;
}
