.partners-header{
    font-weight: 300;
}
.partners-header::after{
    display: block;
    height: 2px;
    content: '';
    width: 40px;
    background: #000000;
    margin-top: 20px;
}

.partners-list {
    position: absolute;
    top: 50%;
    width: 800px;
    height: 200px;
    left: 50%;
    margin-left: -400px;
    margin-top: -130px;
}

.partners-list li {
    width: 25%;
    list-style-type: none;
    position: absolute;
    top: 0;
    height: 200px;
    opacity: 0;
    padding: 40px 20px 20px;
    text-align: center;
    transition: 1s opacity;
}

.active {
    opacity: 1;
}

.list-container{
    display: flex;
    max-width: 100%;
    animation: rotateAnimation 10s linear infinite;
}

.list-img img{
    object-fit: contain;
}

@keyframes rotateAnimation {
    0% {transform: translateX(0);}
    50% {transform: translateX(-100vh);}
    100% {transform: translateX(0);}
}